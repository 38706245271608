export const apiDomain = 'webapi.autoexpert.moscow';
export const timezoneOffsetCurrent = 3;
export const domains = [
  {
    domain: "autoexpert.moscow",
    slug: "autoexpert-moscow",
    id: 44,
    isIndex: true
  },
  {
    domain: "expert-auto.moscow",
    slug: "expert-auto-moscow",
    id: 51,
    isIndex: true
  },
  {
    domain: "xn-----6kcchct3bctrfjpmggh7r.xn--p1ai", //авто-эксперт-москва.рф
    slug: "avto-expert-moskva-rf",
    id: 50,
    isIndex: true
  },
  {
    domain: "expertauto-mos.ru",
    slug: "expertauto-mos",
    id: 149,
    isIndex: true
  },
];
export const devDomain = {
  domain: "autoexpert.moscow",
  slug: "autoexpert-moscow",
  id: 44,
  isIndex: false
};
export function currentDomain(host) {
  return domains.find(value => value.domain === host) || devDomain
}
